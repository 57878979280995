import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DataTable from 'components/NewLayout/Table';
import CustomButton from 'components/NewLayout/Button';

const AccordionTable = ({
  items,
  title,
  columns,
  loading,
  dataSource
}: {
  items: any[];
  title?: string;
  columns?: { prop: string; name: string; data: string }[];
  loading?: boolean;
  dataSource?: (pageNumber: number, pageSize: number, searchText: string) => void;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion
      defaultExpanded
      sx={{ border: '1px solid #000', borderRadius: '5px', marginBottom: '15px' }}>
      <AccordionSummary>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: '10px'
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '24px',
                lineHeight: '30px',
                textAlign: 'left',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none'
              }}>
              {title} |
            </Typography>
            <Box>
              <CustomButton
                onClick={() => console.log('Zone 2 Btn click')}
                label="Scene 1"
                sx={{ borderRadius: '20px', paddingY: '3px', marginLeft: '10px' }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                backgroundColor: '#4E4E4E',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                padding: '5px 15px',
                borderRadius: '5px'
              }}>
              <Typography sx={{ color: '#fff', fontWeight: '600', marginRight: '5px' }}>
                Total Items
              </Typography>
              <Typography
                sx={{
                  borderRadius: '8px',
                  width: '25px',
                  height: '25px',
                  backgroundColor: '#c32051',
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                2
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleToggle}>
                {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <DataTable
          items={items}
          columns={columns}
          loading={loading}
          totalCount={0}
          serverSidePagination
          dataSource={dataSource}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionTable;
