import { Add, Search } from '@mui/icons-material';
import { Box } from '@mui/material';
import DataTable from 'components/NewLayout/Table';
import { useEffect, useState } from 'react';
import Analytics from './Analytics';
import PaperContainer from 'components/PaperContainer';
import SearchHeader from './SearchHeader';
import type { User } from 'api/types';

type ColumnType = (
  | {
      name: string;
      prop: string;
      data: (row: any) => JSX.Element;
    }
  | {
      prop: string;
      name: string;
      data: string;
    }
)[];

type EstimateType = {
  id: number;
  name: string;
  value: number;
};

type PaginationDataType = {
  pageNumber: string;
  pageSize: string;
  searchText: string;
};
export default function EstimatesList({
  estimatesListColumns,
  isLoading,
  estimatesList,
  setPaginationData,
  setSearchStr,
  searchStr,
  setCreateJob,
  setGetFilterdData,
  setGetEstimate,
  setOpenInitiateEstimateDrawer
}: {
  estimatesListColumns: ColumnType;
  isLoading: boolean;
  estimatesList: any;
  setPaginationData: (data: PaginationDataType) => void;
  setSearchStr: (value: string) => void;
  searchStr: string | null;
  setCreateJob: (data: string | null) => void;
  setGetFilterdData: (data: string | null) => void;
  setGetEstimate: (data: string | null) => void;
  setOpenInitiateEstimateDrawer: (status: boolean) => void;
}) {
  // All Component Func
  const getFilteredSearchData = (data: string) => {
    console.log(data);
    setGetFilterdData(data);
  };

  const getEstimateData = (data: string) => {
    console.log(data);
    setGetEstimate(data);
  };

  const getCreateJobData = (data: string) => {
    console.log(data);
    setCreateJob(data);
  };

  const handleSearchData = (e) => {
    setSearchStr(e.target.value);
    console.log(searchStr);
  };

  return (
    <>
      <Box className="active-inactive-admin-ws-div" sx={{ marginBottom: '20px', flexWrap: 'wrap' }}>
        <Box sx={{ marginX: '10px', marginY: '5px' }}>
          <Analytics title={'Open Estimate'} value={2} backgroundColor={'#c32051'} />
        </Box>
        <Box sx={{ marginX: '10px', marginY: '5px' }}>
          <Analytics title={'Completed Estimates'} value={2} backgroundColor={'#335d87'} />
        </Box>
      </Box>
      <PaperContainer>
        <Box>
          <SearchHeader
            sendEstimateData={getEstimateData}
            sendCreateJobData={getCreateJobData}
            onChange={handleSearchData}
            setOpenInitiateEstimateDrawer={(status: boolean) =>
              setOpenInitiateEstimateDrawer(status)
            }
          />
        </Box>
        <br />
        <DataTable<User>
          items={estimatesList}
          columns={estimatesListColumns}
          loading={isLoading}
          totalCount={0}
          serverSidePagination
          dataSource={(pageNumber, pageSize, searchText) =>
            setPaginationData({ pageNumber, pageSize, searchText })
          }
        />
      </PaperContainer>
    </>
  );
}
