import { Add, Search } from '@mui/icons-material';
import { Box, Button, Grid, InputAdornment, Link, SvgIcon, Typography } from '@mui/material';
import editIcon from 'assets/images/edit-icon.svg';
import CustomButton from 'components/NewLayout/Button';
import CustomTextfield from 'components/NewLayout/Textfield';

const SearchHeader = ({
  sendCreateJobData,
  onChange,
  setOpenInitiateEstimateDrawer
}: {
  sendEstimateData: (data: string) => void;
  sendCreateJobData: (data: string) => void;
  onChange: (data: string) => void;
  setOpenInitiateEstimateDrawer: (status: boolean) => void;
}) => {
  const handleCreateJobData = () => {
    sendCreateJobData('Send Create Job Data Data Sent To Parent');
  };
  return (
    <>
      <Box sx={{ marginY: '15px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 2
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 1
            }}>
            <Typography
              sx={{
                fontWeight: 'bold',
                marginRight: { xs: 0, sm: '5px' },
                fontSize: { xs: '0.9rem', md: '1rem' },
                textAlign: { xs: 'center', sm: 'left' }
              }}>
              Estimates
            </Typography>
            <Button
              sx={{
                borderRadius: '30px',
                padding: { xs: '0px 10px', sm: '0px 15px' },
                color: '#4E4E4E',
                backgroundColor: '#335D8726',
                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                fontWeight: '400',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:hover': { backgroundColor: '#f0f0f0' }
              }}>
              <img src={editIcon} alt="Edit Icon" style={{ fill: '#4E4E4E', width: '14px' }} />
              My Estimate
            </Button>
          </Box>

          <Box
            sx={{
              textAlign: { xs: 'center', sm: 'right' },
              width: { xs: '100%', sm: 'auto' }
            }}>
            <Link href="/" sx={{ color: '#c32051' }}>
              <span
                style={{
                  color: '#c32051',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  fontSize: '0.875rem'
                }}>
                Monday.com Estimate Request Board
              </span>
            </Link>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
            <CustomTextfield
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon component={Search} />
                  </InputAdornment>
                )
              }}
              name="search"
              placeholder="Quick Search"
              onChange={onChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: { xs: 'center', sm: 'flex-end' },
              gap: 2
            }}>
            <CustomButton
              onClick={() => setOpenInitiateEstimateDrawer(true)}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                  <SvgIcon
                    sx={{
                      border: '1px solid #eee',
                      borderRadius: '50%',
                      width: { xs: '18px', md: '20px' },
                      height: { xs: '18px', md: '20px' }
                    }}
                    component={Add}
                  />
                  <Box
                    component="span"
                    sx={{
                      fontSize: { xs: '0.75rem', md: '0.875rem' }
                    }}>
                    Initiate an Estimate
                  </Box>
                </Box>
              }
            />
            <CustomButton
              onClick={handleCreateJobData}
              label={
                <Box
                  sx={{
                    fontSize: { xs: '0.75rem', md: '0.875rem' },

                    padding: '0px'
                  }}>
                  Create Job from Estimate
                </Box>
              }
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchHeader;
