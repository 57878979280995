export const menus = [
  {
    name: 'Ordering & Logistics',
    isNested: true,
    icon: 'order-and-logistic',
    module: 'OrderingAndLogistics',
    subMenus: [
      {
        name: 'Request Workspace',
        isNested: false,
        icon: 'request-workspace',
        link: '/order-requests',
        module: 'ItemRequest'
      },
      {
        name: 'Manual PO Workspace',
        isNested: false,
        icon: 'procurement-workspace',
        link: '/procurement-workspace',
        module: 'ProcurementHub'
      },
      {
        name: 'OCR PO Workspace',
        isNested: false,
        icon: 'procurement-workspace',
        link: '/ocr-po-workspace',
        module: 'ProcurementHub'
      },
      {
        name: 'Receiving Workspace',
        isNested: false,
        icon: 'receiving-workspace',
        link: '/receiving-workspace',
        module: 'ReceivingHub'
      },
      {
        name: 'Payment Workspace',
        isNested: false,
        icon: 'payment-workspace',
        link: '/payment-workspace',
        module: 'PaymentHub'
      },
      {
        name: 'Transportation Workspace',
        isNested: false,
        icon: 'transportation-workspace',
        link: '/transportation-workspace',
        module: 'TransportationHub'
      },
      {
        name: 'Driver Workspace',
        isNested: false,
        icon: 'driver-workspace',
        link: '/driver-workspace',
        module: 'TransportationTrip'
      }
    ]
  },
  {
    name: 'Time Tracking & Attendance',
    isNested: true,
    icon: 'time-tracking-and-attendence',
    module: 'TimeTrackingAndAttendence',
    subMenus: [
      {
        name: 'Punch Form',
        isNested: false,
        icon: 'punch-form',
        link: '/time-tracking-and-attendence/punch-form',
        module: 'PunchForm'
      },
      {
        name: 'Time Workspace',
        isNested: false,
        icon: 'time-workspace',
        link: '/time-tracking-and-attendence/time-workspace',
        module: 'TimeWorkspace'
      }
    ]
  },
  {
    name: 'Job Estimation',
    isNested: true,
    icon: 'job-estimation',
    module: 'JobEstimation',
    subMenus: [
      {
        name: 'Estimate Workspace',
        isNested: false,
        icon: 'estimate-workspace',
        link: '/job-estimation/estimate-workspace',
        module: 'EstimateWorkspace'
      }
    ]
  },
  {
    name: 'Administration',
    isNested: true,
    icon: 'admin-console',
    module: 'AdminHub',
    subMenus: [
      {
        name: 'People Workspace',
        isNested: false,
        icon: 'people-workspace',
        link: '/admin-hub/people-workspace',
        module: 'Users'
      },
      {
        name: 'Vendor Workspace',
        isNested: false,
        icon: 'vendor-workspace',
        link: '/admin-hub/vendor-workspace',
        module: 'Vendors'
      },
      {
        name: 'Job Workspace',
        isNested: false,
        icon: 'job-workspace',
        link: '/admin-hub/job-workspace',
        module: 'Jobs'
      },
      {
        name: 'Vehicle Workspace',
        isNested: false,
        icon: 'vehicle-workspace',
        link: '/admin-hub/vehicle-workspace',
        module: 'Vehicles'
      },
      {
        name: 'Credit Card Workspace',
        isNested: false,
        icon: 'creditcard-workspace',
        link: '/admin-hub/creditcard-workspace',
        module: 'CreditCards'
      },
      {
        name: 'Customer Workspace',
        isNested: false,
        icon: 'customer-workspace',
        link: '/admin-hub/customer-workspace',
        module: 'Customers'
      },
      {
        name: 'Payment Workspace',
        isNested: false,
        icon: 'payment-workspace',
        link: '/payment-workspace',
        module: 'PaymentHub'
      },
      {
        name: 'Integration Workspace',
        isNested: false,
        icon: 'payment-workspace',
        link: '/admin-hub/integration-workspace',
        module: 'AlertHub'
      }
    ]
  },
  {
    name: 'Analytics',
    isNested: false,
    icon: 'analytics',
    link: '/analytics',
    module: 'AnalyticsDashboard'
  }
];
