import { Add, Search } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, InputAdornment, SvgIcon } from '@mui/material';
import dropBoxIcon from 'assets/images/dropbox.svg';
import excelIcon from 'assets/images/excel.svg';
import filterIcon from 'assets/images/filterIcon.svg';
import saveIcon from 'assets/images/save.svg';
import CustomButton from 'components/NewLayout/Button';
import CustomTextfield from 'components/NewLayout/Textfield';

const SearchEstimateItem = ({ onChange }: { onChange: (data: string) => void }) => (
  <>
    <Box>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
            <CustomTextfield
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon component={Search} />
                  </InputAdornment>
                )
              }}
              name="search"
              placeholder="Quick Search"
              onChange={onChange}
            />
            {/* <Button
              onClick={() => console.log('Filter click')}
              sx={{
                borderRadius: '30px',
                padding: { xs: '5px 10px', md: '5px 15px' },
                backgroundColor: '#ebdadf',
                fontSize: { xs: '0.75rem', md: '0.875rem' },
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                '&:hover': { backgroundColor: '#f6dfe6' }
              }}>
              <img src={filterIcon} alt="filter" width="15px" style={{ marginRight: '5px' }} />
              Filter Search
            </Button> */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-end' },
              alignItems: 'center',
              gap: 2,
              flexWrap: 'wrap'
            }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton>
                <img style={{ width: '25px' }} src={dropBoxIcon} alt="Dropbox" />
              </IconButton>
              <IconButton>
                <img style={{ width: '25px' }} src={excelIcon} alt="Excel" />
              </IconButton>
              <IconButton>
                <img style={{ width: '25px' }} src={saveIcon} alt="Save" />
              </IconButton>
            </Box>
            <CustomButton
              onClick={() => console.log('Initiate an Estimate Click')}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <SvgIcon
                    sx={{
                      border: '1px solid #eee',
                      borderRadius: '50%',
                      marginRight: '10px',
                      width: { xs: '18px', md: '20px' },
                      height: { xs: '18px', md: '20px' }
                    }}
                    component={Add}
                  />
                  <Box
                    component="span"
                    sx={{
                      fontSize: { xs: '0.75rem', md: '0.875rem' },
                      fontWeight: '600'
                    }}>
                    Add Items
                  </Box>
                </Box>
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>
);

export default SearchEstimateItem;
