import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography
} from '@mui/material';
import PaperContainer from 'components/PaperContainer';
import React, { useState } from 'react';
import SearchEstimateItem from './SearchEstimateItem';
import CustomButton from 'components/NewLayout/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DataTable from 'components/NewLayout/Table';
import type { User } from 'api/types';
import { Search } from '@mui/icons-material';
import ZoneSlider from './ZoneSlider';
import AccordionTable from './Accordion';

const EstimateItems = ({
  items,
  columns,
  loading,
  dataSource,
  title
}: {
  items: [];
  columns: { prop: string; name: string; data: string }[];
  loading: boolean;
  dataSource?: (pageNumber: number, pageSize: number, searchText: string) => void;
  title?: string;
}) => {
  console.log('remove after add hooks or function');
  return (
    <>
      <Box sx={{ marginY: '20px' }}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Poppins',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '30px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none'
          }}>
          Animals Of Earth - Estimate Items
        </Typography>
      </Box>
      <PaperContainer>
        <Box sx={{ mb: '10px' }}>
          <SearchEstimateItem onChange={() => console.log('input is working')} />
        </Box>
        <Box sx={{ mb: '10px' }}>
          <ZoneSlider />
        </Box>
        {/* Section 1 */}
        <AccordionTable
          title="Zone 1 Get Into Focus"
          items={items}
          columns={columns}
          loading={loading}
          dataSource={dataSource}
        />
        {/* Section 2 */}
        <AccordionTable
          title="Zone 2 Get Into Focus"
          items={items}
          columns={columns}
          loading={loading}
          dataSource={dataSource}
        />
      </PaperContainer>
    </>
  );
};

export default EstimateItems;
