import type {
  GetPurchaseOrders,
  PurchaseOrderItem,
  PurchaseOrderRequestItemRequestBody,
  NetVendorPurchaseOrder,
  OcrImportedPurchaseOrderRequestBody,
  OcrImportedPurchaseOrderItem,
  TransportationTripType,
  OcrImportedPurchaseOrderItemRequestBody,
  PurchaseOrderTransportationRequest,
  PaymentScheduleForm,
  BillUpdateForm,
  PurchaseOrderBills
} from 'api/types';
import type {
  RequestWorkInterface,
  requestItemsObj
} from 'pages/RequestWorkspace/components/RequestWorkspaceTable';
import config from 'config/index';
import axios from 'api/axios';

export function getAllUrgentOrderStatus() {
  return axios.get('/getUrgentOrderStatus').then((res) => res.data);
}

export function createPurchaseOrderItem(requestItems, isNetVendorItemRequest?: string) {
  const params: { bypassRequestWorkspace: string } = {
    bypassRequestWorkspace: isNetVendorItemRequest ?? ''
  };
  return axios
    .post(`/item-requests?${new URLSearchParams(params)}`, requestItems)
    .then((res) => res.data);
}

export function getPurchaseOrderRequestItems(
  pageNumber: string,
  pageSize: string,
  searchText = ''
) {
  const userRoles = localStorage.getItem('userRoles');
  const params: {
    status: string;
    userSub?: string;
    pageNumber: string;
    pageSize: string;
    searchText: string;
  } = {
    status: 'Requested',
    pageNumber,
    pageSize,
    searchText
  };
  if (userRoles) {
    const roles = userRoles?.split(',');
    if (
      !roles.includes('admin') &&
      !roles.includes('logistics') &&
      !roles.includes('project_manager')
    ) {
      params.userSub = localStorage.getItem('userSub') ?? '';
    }
    if (roles.length === 1 && roles[0] === 'basic_user') {
      params.userSub = localStorage.getItem('userSub') ?? '';
    }
  }
  return axios
    .get(`/purchase-order-request-items?${new URLSearchParams(params)}`)
    .then((res) => res.data);
}

export function getMessageByRequestItem(requestId: string) {
  return axios.get(`/purchase-order-request-items/${requestId}/comments`).then((res) => res.data);
}

export function postComment(comment, purchaseOrderRequestItemId: string) {
  return axios
    .post(`/purchase-order-request-items/${purchaseOrderRequestItemId}/comment`, comment)
    .then((res) => res.data);
}

export function deletePurchaseOrderRequestItem(requestItems: requestItemsObj[]) {
  return axios.post('/purchase-order-request-items', { requestItems }).then((res) => res.data);
}

export function getPurchaseOrders(
  status: string,
  pageNumber: string,
  pageSize: string,
  searchText = ''
) {
  const params: {
    status: string;
    pageNumber: string;
    pageSize: string;
    searchText: string;
  } = {
    status,
    pageNumber,
    pageSize,
    searchText
  };
  return axios.get(`/purchase-order?${new URLSearchParams(params)}`).then((res) => res.data);
}

export function getNetVendors(
  pageNumber: string,
  pageSize: string,
  vendor: string,
  searchText = ''
) {
  const params: {
    status: string;
    userSub?: string;
    pageNumber: string;
    pageSize: string;
    searchText: string;
    vendor: string;
  } = {
    status: 'Needs Procurement',
    pageNumber,
    pageSize,
    searchText,
    vendor
  };
  return axios
    .get(`/purchase-order-request-items?${new URLSearchParams(params)}`)
    .then((res) => res.data);
}

export function deleteNetVendorItem(id: string) {
  return axios.delete(`/net-vendor-request-item/${id}`).then((res) => res.data);
}

export function updatePurchaseOrder(purchaseOrder: GetPurchaseOrders, purchaseOrderId: string) {
  return axios
    .patch(`/purchase-order/${purchaseOrderId}`, { purchaseOrder })
    .then((res) => res.data);
}

export function getPurchaseOrderMessages(purchaseOrderId: string) {
  return axios.get(`/purchase-order/${purchaseOrderId}/comments`).then((res) => res.data);
}

export function updatePurchaseOrderItems(purchaseOrderItems: PurchaseOrderItem[]) {
  return axios.patch('/purchase-order-items', { purchaseOrderItems }).then((res) => res.data);
}

export function postPurchaseOrderComment(purchaseOrderId: string, comment: any) {
  return axios.post(`/purchase-order/${purchaseOrderId}/comment`, comment).then((res) => res.data);
}

export function getOcrPurchaseOrders(pageNumber: string, pageSize: string, searchText = '') {
  const params: {
    userSub?: string;
    pageNumber: string;
    pageSize: string;
    searchText: string;
  } = {
    pageNumber,
    pageSize,
    searchText
  };
  const userRoles = localStorage.getItem('userRoles');

  if (userRoles) {
    if (userRoles.includes('project_manager') && !userRoles.includes('admin')) {
      params.userSub = localStorage.getItem('userSub') ?? '';
    }
  }
  return axios
    .get(`/ocr-imported-purchase-orders?${new URLSearchParams(params)}`)
    .then((res) => res.data);
}

export function deleteOcrPurchaseOrderItem(id: string) {
  return axios.delete(`/ocr-imported-purchase-order-item/${id}`).then((res) => res.data);
}

export function postPurchaseOrderTransportationRequest(data: PurchaseOrderTransportationRequest) {
  return axios.post('/purchase-order-transportation-request', data).then((res) => res.data);
}

export function getOcrPurchaseOrderComments(purchaseOrderId: string) {
  return axios
    .get(`/ocr-imported-purchase-order/${purchaseOrderId}/comments`)
    .then((res) => res.data);
}

export function postOcrPurchaseOrderComment(purchaseOrderId: string, comment: any) {
  return axios.post('/ocr-imported-purchase-order/comments', comment).then((res) => res.data);
}

export function getNetVendorComments(netVendorId: string) {
  return axios.get(`/purchase-order-request-items/${netVendorId}/comments`).then((res) => res.data);
}

export function postNetVendorComment(purchaseOrderId: string, comment: any) {
  return axios
    .post(`/purchase-order-request-items/${purchaseOrderId}/comment`, comment)
    .then((res) => res.data);
}

export function updatePurchaseOrderRequestItem(
  requestItem: PurchaseOrderRequestItemRequestBody,
  purchaseOrderRequestItemId
) {
  return axios
    .patch(`/purchase-order-request-items/${purchaseOrderRequestItemId}`, { requestItem })
    .then((res) => res.data);
}

export function updatePurchaseOrderReceivingItem(purchaseOrderItems: PurchaseOrderItem[]) {
  return axios.patch('/purchase-order-items/', { purchaseOrderItems }).then((res) => res.data);
}

export function updateOcrPurchaseOrderRequestItem(
  ocrImportedPurchaseOrderItem: OcrImportedPurchaseOrderItemRequestBody,
  purchaseOrderRequestItemId
) {
  return axios
    .patch(`/ocr-imported-purchase-order-item/${purchaseOrderRequestItemId}`, {
      ocrImportedPurchaseOrderItem
    })
    .then((res) => res.data);
}

export function postNetVendorPurchaseOrder(purchaseOrder: NetVendorPurchaseOrder) {
  return axios.post('/purchase-order/', { purchaseOrder }).then((res) => res.data);
}

export function getPurchaseOrderTransportationRequestComments(
  purchaseOrderTransportationRequestId: string
) {
  return axios
    .get(`/open-transportation-requests/${purchaseOrderTransportationRequestId}/comments`)
    .then((res) => res.data);
}

export function postPurchaseOrderTransportationRequestComment(
  comment: any,
  purchaseOrderTransportationRequestId: string
) {
  return axios
    .post(`/open-transportation-requests/${purchaseOrderTransportationRequestId}/comment`, comment)
    .then((res) => res.data);
}

export function deleteOpenTransportationRequest(purchaseOrderTransportationRequestId: string) {
  return axios
    .delete(`/open-transportation-requests/${purchaseOrderTransportationRequestId}`)
    .then((res) => res.data);
}

export function updateOcrImportedPurchaseOrder(
  ocrImportedPurchaseOrder: OcrImportedPurchaseOrderRequestBody,
  purchaseOrderId: string
) {
  return axios
    .patch(`/ocr-imported-purchase-order/${purchaseOrderId}`, {
      ocrImportedPurchaseOrder
    })
    .then((res) => res.data);
}

export function getOpenTransportationRequests(
  pageNumber: string,
  pageSize: string,
  searchText = '',
  userId = ''
) {
  const params: {
    pageNumber: string;
    pageSize: string;
    searchText: string;
    userId: string;
  } = {
    pageNumber,
    pageSize,
    searchText,
    userId
  };
  return axios
    .get(`/open-transportation-requests?${new URLSearchParams(params)}`)
    .then((res) => res.data);
}

export function postOcrPurchaseOrderItem(
  ocrImportedPurchaseOrderItem: OcrImportedPurchaseOrderItem
) {
  return axios
    .post('/ocr-imported-purchase-order-item', {
      ocrImportedPurchaseOrderItem
    })
    .then((res) => res.data);
}

export function postOcrPurchaseOrder(
  ocrImportedPurchaseOrder: OcrImportedPurchaseOrderItem,
  purchaseOrderId: string
) {
  return axios
    .post(`/purchase-orders/ocr-imported-purchase-order-drafts/${purchaseOrderId}`, {
      ocrImportedPurchaseOrder
    })
    .then((res) => res.data);
}

export function updateBill(paymentSchedules: PaymentScheduleForm[]) {
  return axios
    .post('/purchase-orders/delete-bills', {
      paymentSchedules
    })
    .then((res) => res.data);
}

export function deletePurchaseOrderBills(billIds: { id: number }[]) {
  return axios
    .post('/purchase-order-request-items?billRequest=true', { billIds })
    .then((res) => res.data);
}

export function postTransportationTrip(transportationTrip: TransportationTripType) {
  return axios.post(`/transportation-trip`, { transportationTrip }).then((res) => res.data);
}

export function getDriverTransportationTrips(status: boolean) {
  return axios.get(`/driver-transportation-trips?isAll=${status}`).then((res) => res.data);
}

export function updateTransportationTripByPurchaseOrderTransportationRequest(
  transportation_trip_id: string,
  purchase_order_transportation_request_id: string,
  requestData: any
) {
  return axios
    .patch(
      `/transportation-trip/${transportation_trip_id}/purchase-order-transportation-request/${purchase_order_transportation_request_id}`,
      { requestData }
    )
    .then((res) => res.data);
}

export function updateTransportationTrip(transportation_trip_id: string, transportationTrip: any) {
  return axios
    .patch(`/transportation-trips/${transportation_trip_id}`, {
      transportationTrip
    })
    .then((res) => res.data);
}

export function updatePurchaseOrderTransportationRequest(
  purchase_order_transportation_request_id: string,
  transportationRequest: any
) {
  return axios
    .patch(`/purchase-order-transportation-request/${purchase_order_transportation_request_id}`, {
      transportationRequest
    })
    .then((res) => res.data);
}

export function getAssignedTransportationRequests(
  status: string,
  isAll: boolean,
  pageNumber: string,
  pageSize: string,
  searchText = ''
) {
  const params: {
    status: string;
    isAll?: string;
    pageNumber: string;
    pageSize: string;
    searchText: string;
  } = {
    status,
    pageNumber,
    pageSize,
    searchText
  };
  if (status) {
    params.status = status;
  }
  if (isAll) {
    params.isAll = 'true';
  }
  return axios.get(`/transportation-trips?${new URLSearchParams(params)}`).then((res) => res.data);
}

export function deleteTransportationTripRequest(purchaseOrderTransportationTripId: string) {
  return axios
    .delete(`/transportation-trips/${purchaseOrderTransportationTripId}`)
    .then((res) => res.data);
}

export function getPurchaseOrderTransportationTripComments(
  purchaseOrderTransportationTripId: string
) {
  return axios
    .get(`/transportation-trips/${purchaseOrderTransportationTripId}/comments`)
    .then((res) => res.data);
}

export function postPurchaseOrderTransportationTripComment(
  comment: any,
  purchaseOrderTransportationTripId: string
) {
  return axios
    .post(`/transportation-trips/${purchaseOrderTransportationTripId}/comment`, comment)
    .then((res) => res.data);
}

export function updatePurchaseOrderTransportationTrip(
  transportationTrip: TransportationTripType,
  transportationTripId: string
) {
  return axios
    .patch(`/transportation-trips/${transportationTripId}`, {
      transportationTrip
    })
    .then((res) => res.data);
}

export function updatePurchaseOrderRequestItems(requestItems: RequestWorkInterface[]) {
  return axios
    .patch('/purchase-order-request-items/update-all', { requestItems })
    .then((res) => res.data);
}

export async function postOauthgetToken(requestItems, authorization?: boolean) {
  const url = config.awsCognitoAuthUrl + 'oauth2/token';
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: requestItems.toString()
  });
  return response.json();
}

export function getLoggedInUserData() {
  return axios.get('/user-data').then((res) => res.data);
}

export async function bulkUpdateValuesOcrImportedPO(
  ocr_imported_purchase_order_draft_id: string,
  column_name: string,
  value: string,
  ocrImportedPurchaseOrderDraftItems: { ocr_imported_purchase_order_draft_item_id: string }[]
) {
  return axios
    .patch(
      `ocr-imported-purchase-order/${ocr_imported_purchase_order_draft_id}/${column_name}/${value}`,
      {
        ocrImportedPurchaseOrderDraftItems
      }
    )
    .then((res) => res.data);
}

export async function bulkDeleteProjectNameOcrImportedPO(
  ocrImportedPurchaseOrderItems: { ocr_imported_purchase_order_draft_item_id: string }[]
) {
  return axios
    .post(`ocr-imported-purchase-order-items`, {
      ocrImportedPurchaseOrderItems
    })
    .then((res) => res.data);
}

export function duplicatePurchaseOrder(purchaseOrder) {
  return axios.post(`/duplicate-purchase-order`, { purchaseOrder }).then((res) => res.data);
}

export function updateTransportationTripStopsSequence(
  transportationTripId: string,
  stopsData: any
) {
  return axios
    .patch(`/transportation-trip/${transportationTripId}`, { stopsData })
    .then((res) => res.data);
}

export function addStopToTransportationTrip(transportationTripId: string, stopsData: any) {
  return axios
    .post(`/transportation-trip/${transportationTripId}/add-stop`, stopsData)
    .then((res) => res.data);
}

export function getAllPaymentSchedule() {
  const userRoles = localStorage.getItem('userRoles');
  const params: {
    userSub?: string;
  } = {};
  if (userRoles) {
    const roles = userRoles?.split(',');
    if (!roles.includes('admin') && !roles.includes('logistics')) {
      params.userSub = localStorage.getItem('userSub') ?? '';
    }
    if (roles.length === 1 && roles[0] === 'basic_user') {
      params.userSub = localStorage.getItem('userSub') ?? '';
    }
  }
  return axios.get(`/payment-schedule?${new URLSearchParams(params)}`).then((res) => res.data);
}

export function postPaymentSchedule(paymentSchedules: PurchaseOrderBills, purchaseOrderId: string) {
  return axios
    .post(`/payment-schedule/purchaseOrderId/${purchaseOrderId}`, paymentSchedules)
    .then((res) => res.data);
}

export function updatePaymentSchedule(paymentSchedule: BillUpdateForm, billId: number) {
  return axios
    .patch(`/payment-schedule/billId/${billId}`, { paymentSchedule })
    .then((res) => res.data);
}

export function deleteOcrImportedPurchaseOrder(id: string) {
  return axios.delete(`/ocr-imported-purchase-order/${id}`).then((res) => res.data);
}

export * from 'api/adminHub';
