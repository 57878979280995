import React from 'react';
import { Box, Typography } from '@mui/material';

interface AnalyticsProps {
  backgroundColor?: string;
  title?: string;
  value?: string | number;
}

const Analytics: React.FC<AnalyticsProps> = ({ backgroundColor, title, value }) => (
  <Box>
    <Box
      sx={{
        backgroundColor: '#fff',
        padding: '15px',
        borderRadius: '10px',
        minWidth: '400px'
      }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '24px',
            color: '#000',
            fontFamily: 'poppins',
            fontWeight: '800',
            lineHeight: '36px',
            width: '40%'
          }}>
          {title}
        </Typography>
        <Box
          sx={{
            backgroundColor,
            padding: '15px 25px',
            borderRadius: '10px',
            marginLeft: '10px'
          }}>
          <Typography
            sx={{
              fontSize: '24px',
              color: '#fff',
              fontFamily: 'poppins',
              fontWeight: '800',
              lineHeight: '36px'
            }}>
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default Analytics;
