import { Box, Button } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import 'assets/css/style.css';
import { useState } from 'react';

const slideItems = [
  'All Zones',
  'Zone 1 - Get into Focus',
  'Zone 5 - Data Room',
  "Zone 2 - Joel's Journey",
  'Zone 3&4 - Gallery of Gears',
  'Zone 5 - Gallery of Gears',
  'Zone 5 - Data Room',
  "Zone 2 - Joel's Journey",
  'Zone 3&4 - Gallery of Gears',
  'Zone 5 - Gallery of Gears',
  'Zone 5 - Data Room',
  "Zone 2 - Joel's Journey",
  'Zone 3&4 - Gallery of Gears',
  'Zone 5 - Gallery of Gears',
  'Zone 5 - Data Room',
  "Zone 2 - Joel's Journey",
  'Zone 3&4 - Gallery of Gears',
  'Zone 5 - Gallery of Gears'
];

const ZoneSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Box sx={{ position: 'relative' }}>
      <Splide
        style={{ overflow: 'hidden' }}
        options={{
          trimSpace: 'move',
          pagination: false,
          perPage: 3,
          arrows: true,
          autoWidth: true,
          type: 'loop'
        }}
        className="splide-custom-arrows">
        {slideItems.map((zone, index) => (
          <SplideSlide key={index} style={{ padding: '20px 0px', textAlign: 'start' }}>
            <Button
              onClick={() => setActiveIndex(index)}
              variant="contained"
              sx={{
                textTransform: 'none',
                backgroundColor: activeIndex === index ? '#D1D5DB' : '#f4f4f4',
                color: activeIndex === index ? '#000' : 'gray',
                borderRadius: '20px',
                fontWeight: 'bold',
                padding: '8px 16px',
                boxShadow: activeIndex === index ? '10px' : 'none',
                marginLeft: '30px',
                opacity: activeIndex === index ? 1 : 0.8,
                '&:hover': {
                  backgroundColor: '#D1D5DB'
                }
              }}>
              {zone}
            </Button>
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};

export default ZoneSlider;
