import { fabClasses } from '@mui/material';
import type { StateCreator } from 'zustand';

export type Permission = {
  role: 'basic_user' | 'driver' | 'logistics' | 'project_manager' | 'admin';
  module: string;
  read?: boolean;
  create?: boolean;
  update?: boolean;
  delete?: boolean;
};

export interface CreatePermissionSlice {
  permissions: Permission[];
  addPermissions: (permissions: Permission[]) => void;
}

export const createPermissionSlice: StateCreator<CreatePermissionSlice> = (set) => ({
  permissions: [],
  addPermissions: (permissions: Permission[]) =>
    set((state: CreatePermissionSlice) => ({
      permissions
    }))
});

export const rolesPermssions: Permission[] = [
  // Request hub permissions
  {
    role: 'basic_user',
    module: 'RequestHub',
    read: true
  },
  {
    role: 'logistics',
    module: 'RequestHub',
    read: true
  },
  {
    role: 'project_manager',
    module: 'RequestHub',
    read: true
  },
  {
    role: 'driver',
    module: 'RequestHub',
    read: true
  },
  {
    role: 'admin',
    module: 'RequestHub',
    read: true
  },
  // item requests permissions
  {
    role: 'basic_user',
    module: 'ItemRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'logistics',
    module: 'ItemRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'ItemRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'ItemRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'admin',
    module: 'ItemRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // item requests comments permissions
  {
    role: 'basic_user',
    module: 'ItemRequestComments',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'logistics',
    module: 'ItemRequestComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'project_manager',
    module: 'ItemRequestComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'driver',
    module: 'ItemRequestComments',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'admin',
    module: 'ItemRequestComments',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Procurement hub permissions
  {
    role: 'basic_user',
    module: 'ProcurementHub',
    read: false
  },
  {
    role: 'logistics',
    module: 'ProcurementHub',
    read: true
  },
  {
    role: 'project_manager',
    module: 'ProcurementHub',
    read: true
  },
  {
    role: 'driver',
    module: 'ProcurementHub',
    read: false
  },
  {
    role: 'admin',
    module: 'ProcurementHub',
    read: true
  },
  // Net vendor item requests permissions
  {
    role: 'basic_user',
    module: 'NetVendorItemRequest',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'NetVendorItemRequest',
    read: true,
    create: false,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'NetVendorItemRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'NetVendorItemRequest',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'NetVendorItemRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // OCR ImportedPurchase orders permissions
  {
    role: 'basic_user',
    module: 'OCRImportedPurchaseOrder',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'OCRImportedPurchaseOrder',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'OCRImportedPurchaseOrder',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'OCRImportedPurchaseOrder',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'OCRImportedPurchaseOrder',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // OCR Imported Purchase order item permissions
  {
    role: 'basic_user',
    module: 'OCRImportedPurchaseOrderItem',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'OCRImportedPurchaseOrderItem',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'OCRImportedPurchaseOrderItem',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'OCRImportedPurchaseOrderItem',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'OCRImportedPurchaseOrderItem',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Receiving hub permissions
  {
    role: 'basic_user',
    module: 'ReceivingHub',
    read: false
  },
  {
    role: 'logistics',
    module: 'ReceivingHub',
    read: true
  },
  {
    role: 'project_manager',
    module: 'ReceivingHub',
    read: true
  },
  {
    role: 'driver',
    module: 'ReceivingHub',
    read: false
  },
  {
    role: 'admin',
    module: 'ReceivingHub',
    read: true
  },
  // Payment hub permissions
  {
    role: 'basic_user',
    module: 'PaymentHub',
    read: false
  },
  {
    role: 'logistics',
    module: 'PaymentHub',
    read: true,
    update: true
  },
  {
    role: 'project_manager',
    module: 'PaymentHub',
    read: true,
    update: true
  },
  {
    role: 'driver',
    module: 'PaymentHub',
    read: false
  },
  {
    role: 'admin',
    module: 'PaymentHub',
    read: true,
    update: true
  },
  // Purchase order permissions
  {
    role: 'basic_user',
    module: 'PurchaseOrder',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'PurchaseOrder',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'PurchaseOrder',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'driver',
    module: 'PurchaseOrder',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'PurchaseOrder',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Purchase order item permissions
  {
    role: 'basic_user',
    module: 'PurchaseOrderItem',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'PurchaseOrderItem',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'PurchaseOrderItem',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'PurchaseOrderItem',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'PurchaseOrderItem',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Purchase order comments permissions
  {
    role: 'basic_user',
    module: 'PurchaseOrderComments',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'PurchaseOrderComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'project_manager',
    module: 'PurchaseOrderComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'driver',
    module: 'PurchaseOrderComments',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'PurchaseOrderComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  // Transportation hub permissions
  {
    role: 'basic_user',
    module: 'TransportationHub',
    read: true
  },
  {
    role: 'logistics',
    module: 'TransportationHub',
    read: true
  },
  {
    role: 'project_manager',
    module: 'TransportationHub',
    read: true
  },
  {
    role: 'driver',
    module: 'TransportationHub',
    read: true
  },
  {
    role: 'admin',
    module: 'TransportationHub',
    read: true
  },
  // Purchase order transportation request permissions
  {
    role: 'basic_user',
    module: 'PurchaseOrderTransportationRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'logistics',
    module: 'PurchaseOrderTransportationRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'PurchaseOrderTransportationRequest',
    read: true,
    create: true,
    update: true,
    delete: false
  },
  {
    role: 'driver',
    module: 'PurchaseOrderTransportationRequest',
    read: true,
    create: false,
    update: true,
    delete: false
  },
  {
    role: 'admin',
    module: 'PurchaseOrderTransportationRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Purchase order transportation request comments permissions
  {
    role: 'basic_user',
    module: 'PurchaseOrderTransportationRequestComments',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'PurchaseOrderTransportationRequestComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'project_manager',
    module: 'PurchaseOrderTransportationRequestComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'driver',
    module: 'PurchaseOrderTransportationRequestComments',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'PurchaseOrderTransportationRequestComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  // transportation request permissions
  {
    role: 'basic_user',
    module: 'ItemTransportationRequest',
    read: false,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'ItemTransportationRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'ItemTransportationRequest',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'driver',
    module: 'ItemTransportationRequest',
    read: true,
    create: true,
    update: true,
    delete: false
  },
  {
    role: 'admin',
    module: 'ItemTransportationRequest',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // transportation trip permissions
  {
    role: 'basic_user',
    module: 'TransportationTrip',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'TransportationTrip',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'TransportationTrip',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'driver',
    module: 'TransportationTrip',
    read: true,
    create: false,
    update: true,
    delete: false
  },
  {
    role: 'admin',
    module: 'TransportationTrip',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // transportation trip comments permissions
  {
    role: 'basic_user',
    module: 'TransportationTripComments',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'TransportationTripComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'project_manager',
    module: 'TransportationTripComments',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'driver',
    module: 'TransportationTripComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'TransportationTripComments',
    read: true,
    create: true,
    update: false,
    delete: false
  },
  // Admin hub permissions
  {
    role: 'basic_user',
    module: 'AdminHub',
    read: true
  },
  {
    role: 'logistics',
    module: 'AdminHub',
    read: true
  },
  {
    role: 'project_manager',
    module: 'AdminHub',
    read: true
  },
  {
    role: 'driver',
    module: 'AdminHub',
    read: true
  },
  {
    role: 'admin',
    module: 'AdminHub',
    read: true
  },
  // Users permissions
  {
    role: 'basic_user',
    module: 'Users',
    read: true,
    create: false,
    update: true,
    delete: false
  },
  {
    role: 'logistics',
    module: 'Users',
    read: true,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'project_manager',
    module: 'Users',
    read: true,
    create: false,
    update: true,
    delete: false
  },
  {
    role: 'driver',
    module: 'Users',
    read: true,
    create: false,
    update: true,
    delete: false
  },
  {
    role: 'admin',
    module: 'Users',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Vendors permissions
  {
    role: 'basic_user',
    module: 'Vendors',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'Vendors',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'Vendors',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'Vendors',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'Vendors',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Jobs permissions
  {
    role: 'basic_user',
    module: 'Jobs',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'Jobs',
    read: true,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'project_manager',
    module: 'Jobs',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'Jobs',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'Jobs',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Vehicles permissions
  {
    role: 'basic_user',
    module: 'Vehicles',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'Vehicles',
    read: true,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'project_manager',
    module: 'Vehicles',
    read: true,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'driver',
    module: 'Vehicles',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'Vehicles',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // CreditCards permissions
  {
    role: 'basic_user',
    module: 'CreditCards',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'CreditCards',
    read: true,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'project_manager',
    module: 'CreditCards',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'CreditCards',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'CreditCards',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Customer permissions
  {
    role: 'basic_user',
    module: 'Customers',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'Customers',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'Customers',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'Customers',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'Customers',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Alerts permissions
  {
    role: 'basic_user',
    module: 'AlertHub',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'AlertHub',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'AlertHub',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'AlertHub',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'AlertHub',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // Quickbooks alert permissions
  {
    role: 'basic_user',
    module: 'QuickBooksAlert',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'logistics',
    module: 'QuickBooksAlert',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'project_manager',
    module: 'QuickBooksAlert',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'driver',
    module: 'QuickBooksAlert',
    read: false,
    create: false,
    update: false,
    delete: false
  },
  {
    role: 'admin',
    module: 'QuickBooksAlert',
    read: true,
    create: true,
    update: true,
    delete: true
  },

  //OrderingAndLogistics
  {
    role: 'basic_user',
    module: 'OrderingAndLogistics',
    read: true
  },
  {
    role: 'logistics',
    module: 'OrderingAndLogistics',
    read: true
  },
  {
    role: 'project_manager',
    module: 'OrderingAndLogistics',
    read: true
  },
  {
    role: 'driver',
    module: 'OrderingAndLogistics',
    read: true
  },
  {
    role: 'admin',
    module: 'OrderingAndLogistics',
    read: true
  },
  // TimeTrackingAndAttendence
  {
    role: 'admin',
    module: 'TimeTrackingAndAttendence',
    read: true
  },
  {
    role: 'basic_user',
    module: 'TimeTrackingAndAttendence',
    read: true
  },
  {
    role: 'logistics',
    module: 'TimeTrackingAndAttendence',
    read: true
  },
  {
    role: 'project_manager',
    module: 'TimeTrackingAndAttendence',
    read: true
  },
  {
    role: 'driver',
    module: 'TimeTrackingAndAttendence',
    read: true
  },
  // JobEstimation
  {
    role: 'admin',
    module: 'JobEstimation',
    read: true
  },
  {
    role: 'project_manager',
    module: 'JobEstimation',
    read: true
  },
  //AnalyticsDashboard
  {
    role: 'admin',
    module: 'AnalyticsDashboard',
    read: true
  },
  {
    role: 'project_manager',
    module: 'AnalyticsDashboard',
    read: true
  },
  // Punch Form permissions
  {
    role: 'basic_user',
    module: 'PunchForm',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'logistics',
    module: 'PunchForm',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'PunchForm',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'PunchForm',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'admin',
    module: 'PunchForm',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // TimeWorkspace permissions
  {
    role: 'basic_user',
    module: 'TimeWorkspace',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'logistics',
    module: 'TimeWorkspace',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'TimeWorkspace',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'TimeWorkspace',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'admin',
    module: 'TimeWorkspace',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  // EstimateWorkspace permissions
  {
    role: 'basic_user',
    module: 'EstimateWorkspace',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'logistics',
    module: 'EstimateWorkspace',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'project_manager',
    module: 'EstimateWorkspace',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'driver',
    module: 'EstimateWorkspace',
    read: true,
    create: true,
    update: true,
    delete: true
  },
  {
    role: 'admin',
    module: 'EstimateWorkspace',
    read: true,
    create: true,
    update: true,
    delete: true
  }
];
