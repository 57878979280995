import { useEffect, useState } from 'react';
import { useCommonStore } from 'store/index';
import { tabTitle } from 'utils/index';
import type { User } from 'api/types';
import EstimatesList from './components/EstimatesList';
import EstimateItems from './components/EstimateItems';
import { Box, IconButton } from '@mui/material';
import NoDocuments from './components/NoDocuments';
import DeleteIcon from 'assets/images/delete-icon.svg';
import { useAdminWorkspaceStore } from 'store/index';
type PaginationDataType = {
  pageNumber: string;
  pageSize: string;
  searchText: string;
};

export default function EstimateWorkspace() {
  const [data, setData] = useState<User[]>([]);
  const [selectedEstimatesId, setSelectedEstimatesId] = useState<string>('');
  const [estimatesList, setEstimatesList] = useState<any>([]);
  const [estimateItemsList, setEstimateItemsList] = useState<any>([]);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [openInitiateEstimate, setOpenInitiateEstimateDrawer] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState<string | null>(null);
  const [createJob, setCreateJob] = useState<string | null>(null);
  const [getFilterddata, setGetFilterdData] = useState<string | null>(null);
  const [getEstimate, setGetEstimate] = useState<string | null>(null);
  const [paginationData, setPaginationData] = useState<PaginationDataType>({
    pageNumber: '0',
    pageSize: '10',
    searchText: ''
  });
  const handleDataSource = (pageNumber, pageSize, searchText) => {
    setPaginationData({ pageNumber, pageSize, searchText });
  };
  useAdminWorkspaceStore.subscribe((state) => {
    setData(state.allUsers);
  });
  const getData = (pageNumber: string, pageSize: string, searchText = '') => {
    setIsloading(true);

    setIsloading(false);
  };
  useEffect(() => {
    const { pageNumber, pageSize, searchText } = paginationData;
    getData(pageNumber, pageSize, searchText);
  }, [paginationData]);
  tabTitle('Estimate Master Workspace');
  const setComponentHeading = useCommonStore((state) => state.setComponentHeading);

  useEffect(() => {
    setComponentHeading('Estimate Master Workspace');
  }, []);
  useEffect(() => {
    setEstimatesList([
      {
        id: 'estimates-data-1',
        job_name: 'estimates-data-1',
        job_type: 'estimates-data-1',
        total: 'estimates-data-1',
        estimate_items: 'estimates-data-1',
        customer: 'estimates-data-1',
        assignee: 'estimates-data-1',
        link_to_dropbox: 'estimates-data-1'
      },
      {
        id: 'estimates-data-2',
        job_name: 'estimates-data-2',
        job_type: 'estimates-data-2',
        total: 'estimates-data-2',
        estimate_items: 'estimates-data-2',
        customer: 'estimates-data-2',
        assignee: 'estimates-data-2',
        link_to_dropbox: 'estimates-data-2'
      }
    ]);
  }, []);

  useEffect(() => {
    if (searchStr) {
      getData('', '', searchStr);
    }
  }, [searchStr]);

  const handleRowSelect = (row: any) => {
    setSelectedEstimatesId(row.id);
    setEstimateItemsList([
      {
        id: 'data-1',
        action: (
          <IconButton color="error">
            <img src={DeleteIcon} />
          </IconButton>
        ),
        estimate_item: 'estimate-items-estimate_item-1',
        quantity: 'estimate-items-quantity-1',
        total: 'estimate-items-total-1',
        mtls: 'estimate-items-mtls-1',
        total_mtls_50: 'estimate-items-total_mtls_50-1',
        non_value: 'estimate-items-non_value-1',
        non_value_50: 'estimate-items-non_value_2-1'
      },
      {
        id: 'estimate-items-data-2',
        action: (
          <IconButton color="error">
            <img src={DeleteIcon} />
          </IconButton>
        ),
        estimate_item: 'estimate-items-estimate_item-2',
        quantity: 'estimate-items-quantity-2',
        total: 'estimate-items-total-2',
        mtls: 'estimate-items-mtls-2',
        total_mtls_50: 'estimate-items-total_mtls_50-2',
        non_value: 'estimate-items-non_value-2',
        non_value_50: 'estimate-items-non_value_2-2'
      }
    ]);
  };

  const estimatesListColumns = [
    {
      name: '',
      prop: 'select',
      data: (row: any) => (
        <input
          type="radio"
          name="rowSelect"
          onChange={() => handleRowSelect(row)}
          checked={selectedEstimatesId === row.id}
          className="custom-radio"
        />
      )
    },
    {
      prop: 'estimate_id',
      name: 'Estimate ID',
      data: 'estimate_id'
    },
    {
      prop: 'job_name',
      name: 'Job Name',
      data: 'job_name'
    },
    {
      prop: 'job_type',
      name: 'Job Type',
      data: 'job_type'
    },
    {
      prop: 'total',
      name: 'Total',
      data: 'total'
    },
    {
      prop: 'estimate_items',
      name: 'Estimate Items',
      data: 'estimate_items'
    },
    {
      prop: 'customer',
      name: 'Customer',
      data: 'customer'
    },
    {
      prop: 'assignee',
      name: 'Assignee',
      data: 'assignee'
    },
    {
      prop: 'link_to_dropbox',
      name: 'Link To DropBox',
      data: 'link_to_dropbox'
    }
  ];

  const estimateItemsListColumns = [
    {
      prop: 'action',
      name: 'Action',
      data: 'action'
    },
    {
      prop: 'estimate_item',
      name: 'Estimate Item',
      data: 'estimate_item'
    },
    {
      prop: 'quantity',
      name: 'Quantity',
      data: 'quantity'
    },
    {
      prop: 'total',
      name: 'Total',
      data: 'total'
    },
    {
      prop: 'mtls',
      name: 'MTLS',
      data: 'mtls'
    },
    {
      prop: 'total_mtls_50',
      name: 'Total MTLS +50%',
      data: 'total_mtls_50'
    },
    {
      prop: 'non_value',
      name: 'Non - Value',
      data: 'non_value'
    },
    {
      prop: 'non_value_50',
      name: 'Non - Value +50%',
      data: 'non_value_50'
    }
  ];

  return (
    <>
      <EstimatesList
        estimatesListColumns={estimatesListColumns}
        setPaginationData={setPaginationData}
        estimatesList={estimatesList}
        setSearchStr={setSearchStr}
        searchStr={searchStr}
        isLoading={isLoading}
        setCreateJob={setCreateJob}
        setGetFilterdData={setGetFilterdData}
        setGetEstimate={setGetEstimate}
        setOpenInitiateEstimateDrawer={(status: boolean) => setOpenInitiateEstimateDrawer(status)}
      />
      {selectedEstimatesId ? (
        <>
          <EstimateItems
            items={estimateItemsList}
            columns={estimateItemsListColumns}
            loading={isLoading}
            dataSource={handleDataSource}
          />
        </>
      ) : (
        <Box sx={{ marginY: '15px' }}>
          <NoDocuments />
        </Box>
      )}
    </>
  );
}
