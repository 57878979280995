import { Box, Typography } from '@mui/material';
import NoDoc from 'assets/images/NoDoc.svg';

const NoDocuments = () => (
  <>
    <Box>
      <Box
        sx={{
          border: '1px solid #000',
          height: '400px',
          borderRadius: '10px',
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}>
          <Box>
            <Typography
              component={'img'}
              src={NoDoc}
              sx={{
                width: 'fit-content',
                maxHeight: '250px'
              }}
            />
          </Box>
          <Typography sx={{ fontWeight: 'bold', fontSize: '18px', marginTop: '10px' }}>
            Please Select Estimation from above to view more information
          </Typography>
        </Box>
      </Box>
    </Box>
  </>
);

export default NoDocuments;
